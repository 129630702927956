.customeform-section{
    .form-group{
        margin-bottom: 25px;
        label{
            font-size: 16px;
            color:var(--gray-1);
        }
        .form-control{
            font-size: 14px;
            color:var(--gray-1);
            height: 40px;
            &:focus{
                box-shadow: none;
            }
        }
    }
}

.inputnumber-width{
    width: 100px;
}

.controlfile{
    font-size: 14px;
}


.form-group {
    position: relative;
    .invalid-feedback{
        position: absolute;
        bottom: -20px;
        font-size: 12px;
    }
}
.form-control.is-invalid{
    background-image: none;
}


// Placheholder

.form-control::-webkit-input-placeholder {
    color: var(--gray-2);
    opacity: 1;
  }
  
  .form-control::-moz-placeholder {
    color: var(--gray-2);
    opacity: 1;
  }
  
  .form-control:-ms-input-placeholder {
    color: var(--gray-2);
    opacity: 1;
  }
  
  .form-control::-ms-input-placeholder {
    color: var(--gray-2);
    opacity: 1;
  }
  
  .form-control::placeholder {
    color: var(--gray-2);
    opacity: 1;
  }