

.btn-primary {
    color: var(--white-1);
    background-color: var(--blue-2);
    border-color: var(--blue-2);
    font-size: 16px;
    max-width: 240px;
    padding-top: 7px;
    padding-bottom: 7px;
    border-radius: 8px;
    box-shadow: none !important;
    width: 100%;
    border-radius: 5px;


    &.disabled, &:disabled{
        background-color: var(--blue-2) !important;
        border-color: var(--blue-2) !important;
    }
  }

  .btn-outline-primary{
    border-color: var(--blue-2);
    color: var(--blue-2);
    border-radius: 5px;
    width: 160px;
    box-shadow: none !important;

    &:hover{
        background-color: var(--blue-2);
        border-color: var(--blue-2);
    }

    &:active{
        background-color: var(--blue-2) !important;
        border-color: var(--blue-2) !important;
    }

    &.disabled, &:disabled{
        background-color: var(--blue-2) !important;
        border-color: var(--blue-2) !important;
    }
  }


// btn-success

.btn-success {
    color: var(--white-1);
    background-color: var(--green-1) !important;
    border-color: var(--green-1);
    font-size: 16px;
    max-width: 240px;
    padding-top: 7px;
    padding-bottom: 7px;
    border-radius: 8px;
    box-shadow: none !important;
    width: 100%;
    border-radius: 5px;

    &:hover, &:focus{
        background-color: var(--green-2) !important;
        border-color: var(--green-2) !important;
    }

    &:active{
        background-color: var(--green-2) !important;
        border-color: var(--green-2) !important;
    }
   
    &.disabled, &:disabled{
        background-color: var(--green-2) !important;
        border-color: var(--green-2) !important;
    }

  }




