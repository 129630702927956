:root{
    --white-1: #ffffff;
    --black-1: #000000;
    --black-2: #212227;
    --gray-1: #7f889a;
    --gray-2: #d0d0d0;
    --gray-3: #ccd0d7;
    --gray-4: #ebebeb;
    --gray-5: #f7f7f9;
    --blue-1: #1a1b41;
    --blue-2: #0f70b7;
    --blue-3: #bdd4e7;
    --blue-4: #e7f1fd;
    --green-1: #74bb44;
    --green-2: #68a53d;
}