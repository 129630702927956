/* You can add global styles to this file, and also import other style files */


@import './assets/style/color.scss';
@import './assets/style/button.scss';
@import './assets/style/typography.scss';
@import './assets/style/form.scss';


body{
    font-family: 'Nunito Sans', sans-serif;
    color: var(--gray-1);
}


.banner-info{
    .title{
        small{
            font-size: 100%;
            font-weight: 600;
            color: var(--green-1);
        }
    }
}