



h1{
    font-size: 67px;
}

h2{
    font-size: 51px;
}


h3{
    font-size: 38px;
}

h4{
    font-size: 28px;
}

h5{
    font-size: 21px;
}

h6{
    font-size: 16px;
}


h1, h2, h3, h4, h5, h6{
    color: var(--black-1);
    font-weight: 600;
}

a{
    outline: none;
}

p{
    font-weight: 300;
}

.fontsize38{
    font-size: 38px;
}


@media(max-width:767px){

  
}

.first-caps{
    text-transform: lowercase;
    &::first-letter{
        text-transform: uppercase;
    }
}

.overflow-hidden{
    overflow: hidden;
}


.textprimary{
    color: var(--blue-2);
}


.fontweight300{
    font-weight: 300;
}
.fontweight400{
    font-weight: 400;
}
.fontweight600{
    font-weight: 600;
}
.fontweight700{
    font-weight: 700;
}
.fontweight800{
    font-weight: 800;
}


.lineheight-2{
    line-height: 2;
}
.lineheight-15{
    line-height: 1.5;
}



